@use 'sass:meta';

@import './colors';
@import './fonts';
@import './mixins';
@import './styles';

$wrapper-padding-x: 25px;
$wrapper-padding-y: 25px;

:root {
  --logo: url('../images/hex-logo.webp');
  --hex-cursor: url('../../assets/images/hex-cursor.webp');

  --color-background: #{map-get($colors, 'background')};
  --color-background-darker: #{map-get($colors, 'background-darker')};
  --color-primary-100: #{map-get($colors, 'primary-100')};
  --color-primary-200: #{map-get($colors, 'primary-200')};
  --color-primary-500: #{map-get($colors, 'primary-500')};
  --color-surface-100: #{map-get($colors, 'surface-100')};
  --color-surface-200: #{map-get($colors, 'surface-200')};
  --color-surface-300: #{map-get($colors, 'surface-300')};
  --color-surface-400: #{map-get($colors, 'surface-400')};
  --color-surface-500: #{map-get($colors, 'surface-500')};
  --color-surface-600: #{map-get($colors, 'surface-600')};
  --color-surface-700: #{map-get($colors, 'surface-700')};
  --color-error: #{map-get($colors, 'error')};
  --color-text: #{map-get($colors, 'text')};
  --color-text-muted: #{map-get($colors, 'text-muted')};

  --font-size-heading: 4.2em;
  --font-size-body: 4.2em;
  --font-size-caption: 0.95em;
}

::selection {
  color: var(--color-background-darker);
  background-color: var(--color-primary-500);
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: #222834 transparent;
    scrollbar-width: none;
  }
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  &:focus {
    background-color: var(--color-primary-500);
  }
  &:hover {
    background-color: #72809b27;
  }
  border-radius: 0px;
  background-color: #72809b19;
}

.App {
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scrollbar-color: red orange !important;
  scrollbar-width: thin;
}

body {
  margin: 0;
  background: var(--color-background);
  font-family: sans-serif;
}

.icon {
  color: var(--color-primary-500);
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

h2 {
  font-size: 2.8em;
  color: var(--color-text);
  font-family: map-get($fonts, 'heading');
  font-weight: 100;
  letter-spacing: -1.25px;
  line-height: 1.4em;
  padding-top: 15px;
  padding-bottom: 12px;
  margin: 0;

  a {
    &:hover {
      color: var(--color-text);
    }
  }
}

p {
  cursor: inherit;
  @include notSelectable();
  margin: 0;
  padding-bottom: 10px;
  color: var(--color-text);
  font-family: map-get($fonts, 'body');
  font-size: var(--font-size-body);
  font-weight: 300;
  line-height: 1.3em;
  letter-spacing: -1.3px;

  a {
    color: var(--color-primary-500);

    &::before {
      display: none;
    }
    &:hover {
      &::before {
        display: block;
      }

      color: var(--color-text);
    }
  }

  strong {
    font-weight: 500;
    color: var(--color-background-darker);
    background-color: var(--color-text);
  }
}

a {
  @include hexCursor();
  @include out();
  font-weight: 200;
  position: relative;
  text-decoration: none;
  color: var(--color-primary-500);

  &:active {
    color: var(--color-primary-500) !important;
  }
}

ul {
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 5px;

  li {
    margin-bottom: 10px;
    font-family: map-get($fonts, 'body');
    font-size: 1.2em;
    padding-bottom: 5px;
    list-style-type: none;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.center {
  margin: 0 auto;
}

.Mui-error {
  color: var(--color-primary-500) !important;
}

.particles {
  position: absolute;
  z-index: -1;
}

.wrapper {
  max-width: 1000px;
  padding: $wrapper-padding-y $wrapper-padding-x;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.visual-projects-view {
  margin-top: 50px !important;
  padding-bottom: $wrapper-padding-y;
  position: static;
  text-align: left;
  color: white;

  h2 {
    text-transform: lowercase;
  }
}

.work-wrapper {
  width: 87%;
  max-width: 1050px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 60px;

  a {
    color: var(--color-primary-500);

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  ul {
    padding: 0;
    padding-left: 5px;
    padding-bottom: 30px;
  }
}

.button-alt {
  color: var(--color-primary-500);
  background-color: var(--color-primary-200) !important;

  &:hover {
    color: var(--color-background);
    background-color: var(--color-primary-500) !important;

    svg {
      color: var(--color-background);
    }
  }
}

.dev-projects-view {
  margin-top: 60px;

  .dev-project-card {
    @include hexCursor();
    @include out();
    margin-bottom: 20px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 20px;
    padding-bottom: 13px;
    border-radius: map-get($radii, 'sm');
    background-color: transparent;
    box-shadow: none;
    overflow: visible;

    .MuiButton-root {
      font-weight: 400;
    }

    &:hover {
      @include in();

      .MuiButton-root {
        background-color: var(--color-primary-500) !important;
        color: var(--color-background-darker);
      }
      h2 {
        color: var(--color-primary-500);
      }

      strong {
        @include in();
        background-color: var(--color-primary-500);
      }

      p {
        color: var(--color-text-muted);
      }

      li {
        color: var(--color-primary-500);
        background-color: transparent;
        border-color: var(--color-primary-500);
      }

      .dev-project-preview {
        border-color: var(--color-primary-500);
      }
    }

    .dev-project-preview {
      width: 100%;
      height: 250px;
      border-radius: map-get($radii, 'sm');
      border: 1px solid var(--color-surface-200);
      overflow: hidden;
      margin-bottom: 25px;

      img {
        @include out();
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    h2 {
      margin: 0;
      font-family: map-get($fonts, 'body');
      font-weight: 600;
      font-size: 2.2em;
      text-transform: initial;
      line-height: 0.6em;
      letter-spacing: -0.8px;
      color: var(--color-text);
    }

    p {
      font-weight: 200;
      font-size: 1.4em;
      line-height: 1.35em;
      letter-spacing: 0.2px;
    }

    a {
      font-weight: 300;
    }
  }

  p {
    color: var(--color-text-muted);
    font-size: 1.2em;
    line-height: 2.2rem;
    font-weight: 400;
    margin-bottom: 35px;
  }

  h3 {
    font-weight: 300;
  }

  h2 {
    @include in();
    color: var(--color-primary-500);
    text-transform: uppercase;
    font-size: 0.75em;
    line-height: 23px;
    letter-spacing: 2.5px;
  }

  .dev-project-skills {
    @include out();

    ul {
      margin: 0;
      padding: 0;
      columns: 1;

      li {
        @include notSelectable();
        @include in();
        display: inline-block;
        text-transform: lowercase;
        font-family: map-get($fonts, 'mono');
        font-size: 0.9em;
        font-weight: 100;
        padding: 6px 10px;
        color: var(--color-text-muted);
        border: 1px solid var(--color-surface-300);
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: map-get($radii, 'sm');

        &:last-child {
          margin-bottom: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}

.divider {
  @include notSelectable();
  @include notDraggable();

  .line {
    height: 1px;
    width: 100%;
    background-color: var(--color-surface-100);
  }

  img {
    width: 35px;
  }
}

.header-nav {
  @include out();

  a {
    color: var(--color-surface-500);
    font-family: map-get($fonts, 'body');
    font-size: 2em;
    line-height: 1.1em;
    text-transform: lowercase;
    font-weight: 300;
    margin-bottom: 10px;
    letter-spacing: -0.5px;

    &:hover {
      @include in();
      color: var(--color-primary-500) !important;
    }
  }
}

.nav-link-active {
  @include out();
  color: var(--color-primary-500) !important;
  font-weight: 500 !important;

  &::before {
    content: '';
    width: 5px;
    height: 10px;
    background-color: var(--color-primary-500) !important;
  }
}

.floating-nav {
  @include out();
  background-color: transparent;
  position: fixed;
  padding-left: 35px;
  padding-right: 50px;
  padding-top: 40px;
  z-index: 9999;

  a {
    @include out();
    background: transparent;
    display: inline-block; // fixes clipping
    font-family: map-get($fonts, 'body');
    font-size: 1.4em;
    line-height: 1.1em;
    text-transform: lowercase;
    font-weight: 300;
    letter-spacing: -0.1px;
    color: var(--color-surface-500);

    &::before {
      @include in();
      display: none !important;
    }
    &:hover {
      color: var(--color-primary-500) !important;

      &::before {
        @include in();
        background-color: var(--color-primary-500);
      }
    }
  }

  p {
    font-size: 1.1rem;
  }
}

.mobile-nav {
  @include backgroundBlur();
  @include out();
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 65px;
  margin: 10px;
  margin-bottom: 36px;
  background-color: rgba(var(--color-surface-200), 0.3);
  border-radius: map-get($radii, 'sm') + 2px;

  a {
    @include out();
    padding: 5px 20px;
    pointer-events: all;
    background: transparent;
    display: inline-block; // fixes clipping
    font-family: map-get($fonts, 'body');
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: lowercase;
    font-weight: 400;
    letter-spacing: -0.5px;

    &::before {
      height: 0px !important;
      width: 125%;
      left: -12.5%;
    }
    &:hover {
      @include in();
      -webkit-text-fill-color: var(--color-text);
    }
  }
}

.hero {
  cursor: default;
  @include notSelectable();
  position: relative;
  margin-top: 60px;
  margin-bottom: 20px;
  overflow: visible;

  h1 {
    @include in();
    font-size: var(--font-size-heading);
    font-family: map-get($fonts, 'heading');
    font-weight: 300;
    letter-spacing: -1.3px;
    line-height: 1.3em;
    color: var(--color-primary-500);
    margin-top: 0;
    padding: 0;
    text-align: left;

    strong {
      font-weight: 500;
      color: var(--color-background);
      background-color: var(--color-primary-500);
    }

    em {
      color: var(--color-background-darker);
      background-color: var(--color-primary-500);
      font-style: normal;
    }

    a {
      font-size: inherit;

      &:hover {
        color: var(--color-text);
      }
    }
  }
}

.header {
  margin: 0 auto;
  margin-top: 30px;

  p {
    margin-bottom: 0px;
    padding-bottom: 0;
  }
}

.left {
  text-align: left !important;
}

.footer {
  margin-bottom: (-$wrapper-padding-y);
  padding-bottom: $wrapper-padding-y;
  padding-top: 50px;
  // background-color: var(--color-background);
  bottom: 0;

  p {
    font-family: map-get($fonts, 'body');
    font-size: var(--font-size-caption);
    color: var(--color-surface-500);
    padding: 0;
    margin: 0;
    letter-spacing: 0.5px;
  }

  a {
    text-decoration: none;
    color: var(--color-primary-500) !important;
    padding: 0;

    &:hover {
      margin: 0;
      box-shadow: none;
      background-color: transparent;
      color: var(--color-primary-500);
    }
  }
}

.logo {
  $size: 125px;

  @include notSelectable();
  @include notDraggable();
  @include in();
  @include hexCursor();
  width: $size;
  height: $size;
  background-image: var(--logo);
  background-size: contain;
  background-repeat: no-repeat;

  &:active {
    @include out();
    border: none;
    transform: scale(0.9);
  }

  a {
    &::before {
      display: none;
    }
    &:hover {
      background: none;
    }
  }

  img {
    @include notSelectable();
    @include notDraggable();
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

strong {
  font-weight: 700;
}

.visual-project-card {
  @include out();
  @include hexCursor();
  border: 0px solid transparent;
  position: relative;
  margin: 0 auto;
  border-radius: 0px;
  max-width: 500px;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    height: 35%;
    width: 100%;
    opacity: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.47), transparent);
    z-index: 1;
    transition: opacity 0.3s;
  }
  &:hover {
    &::before {
      opacity: 0;
      transition: opacity 0.3s;
    }

    img {
      transform: scale(1.05);
      filter: saturate(100%) contrast(1) brightness(1) invert(0%);
      transition: transform 0.5s, filter 0.5s;
    }

    .visual-project-card-overlay {
      opacity: 0;
      transform: translateX(-300px);
      transition: opacity 0.3s, transform 0.2s;
    }

    .visual-project-palette {
      margin-right: -100%;
      transition: margin-right 0.3s;
    }
  }

  img {
    @include notSelectable();
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(2.4) rotate(10deg);
    filter: saturate(0%) contrast(0.87) brightness(0.55) invert(0%);
    transition: transform 0.7s, filter 0.7s;
  }

  .visual-project-palette {
    margin-right: -0px;
    transition: margin-right 0.3s;
  }
}

.project-type-visual {
  display: block;
}

.visual-project-card-overlay {
  position: absolute;
  z-index: 1000;
  opacity: 1;
  padding: 15px 19px;
  transform: translateX(0px);
  transition: opacity 0.3s, transform 0.2s;

  h2 {
    text-transform: initial;
    color: white;
    font-weight: 600;
    font-size: 3em;
    line-height: 0.85em;
    letter-spacing: -1, 3px;
    margin: 0;
    padding: 0;
    margin-bottom: 7px;
    z-index: 1;
    mix-blend-mode: exclusion;
  }

  label {
    font-family: map-get($fonts, 'body');
    font-weight: 300;
    text-transform: lowercase;
    letter-spacing: -0.2px;
    font-size: 1.5em;
    line-height: 0.9em;
  }

  p {
    color: white;
    padding: 0;
  }

  ul {
    padding: 0;
    margin: 5px;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.left {
  width: 60%;
}

.right {
  float: right;
  margin-top: 0px;
  margin-bottom: 40px;
  padding-left: 25px;
  width: 40%;
}

.hexagon {
  position: absolute;
  z-index: 1;

  .MuiSvgIcon-root {
    fill: var(--color-primary-500);
    width: 90px;
    height: 90px;
  }
}

.hexagon2 {
  position: absolute;
  z-index: -1;

  .MuiSvgIcon-root {
    fill: var(--color-surface-200);
    width: 50px;
    height: 50px;
  }
}

$avatar-size: 190px;

.avatar {
  position: relative;
  padding: 0;
  height: auto;
  width: $avatar-size;
  transition: map-get($transitions, 'exit');

  h2 {
    position: absolute;
    font-size: 2.1rem;
    padding: 0;
    line-height: 2.2rem;
    bottom: 0;
    opacity: 0;
    z-index: 99999;
  }

  img {
    @include hexCursor();
    @include notSelectable();
    @include notDraggable();
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(42%) contrast(100%) invert(0%) hue-rotate(-166deg);
    transition: filter 0.4s;

    &:hover {
      filter: saturate(20%) contrast(320%) invert(100%) hue-rotate(20deg);
      transition: filter 0.3s;
    }
    &:active {
      filter: saturate(100%) contrast(100%) invert(0%) hue-rotate(0deg);
      transition: filter 0.3s;
    }
  }

  &:hover {
    @include in();

    h2 {
      opacity: 1;
    }
  }
}

.fact {
  @include notSelectable();
  @include out();
  margin-left: 0px;
  margin: 0;
  padding-right: 20px;
  padding-bottom: 2px;
  padding-top: 10px;
  max-width: 250px;

  &:hover {
    @include in();

    h2 {
      color: var(--color-text) !important;
    }

    li {
      @include in();
      color: var(--color-primary-500);
      z-index: 9999;
    }

    svg {
      @include out();
      color: var(--color-primary-500);
    }
  }

  h2 {
    @include out();
    font-size: 1.2em;
    font-weight: 400;
    line-height: 0;
    letter-spacing: 0;
    padding-top: 10px; // fixes icon alignment
    text-transform: lowercase;
    color: var(--color-surface-600);
  }

  ul {
    li {
      @include out();
      text-transform: lowercase;
      cursor: default;
      display: inline-block;
      font-family: map-get($fonts, 'body');
      font-size: 2em;
      line-height: 1.72em;
      font-weight: 400;
      padding: 3px 16px;
      border-radius: map-get($radii, 'sm');
      margin-right: 13px;
      margin-bottom: 13px;
      color: var(--color-text-muted);
      background-color: var(--color-background);
    }
  }

  svg {
    @include in();
  }
}

.back-to-top {
  transition: all 0.25s cubic-bezier(0.54, 0.16, 0.1, 0.86);
  position: fixed;
  padding: 10px;
  width: 65px;
  height: 65px;
  right: -5px;
  color: var(--color-primary-500);
  z-index: 9999;
}

/* Mobile: 1000px */
@media (max-width: 1000px) {
  $wrapper-padding-x: 25px;
  $wrapper-padding-y: 25px;

  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }

  .wrapper {
    padding-left: $wrapper-padding-x;
    padding-right: $wrapper-padding-x;
  }

  .button-full-width {
    width: 100%;
  }

  .header-nav {
    font-size: 0.9em;
  }

  .visual-project-card-overlay {
    h2 {
      font-size: 1.6em;
      line-height: 1em;
      letter-spacing: -0.6px;
    }

    label {
      font-size: 1.2em;
      line-height: 1.2em;
    }
  }

  .visual-project-palette {
    display: none !important;
  }

  .back-to-top {
    right: -15px;
  }

  $avatar-size: 150px;
  .avatar {
    width: $avatar-size;
    margin-top: 0;
  }

  .dev-projects-view {
    h3 {
      font-size: 1.3em;
    }

    .dev-project-card {
      min-width: 100%;
      margin-right: 0;

      h2 {
        font-size: 2em;
      }
      h3 {
        margin-bottom: 0;
      }

      p {
        font-size: 1.3em !important;
        line-height: 2.1rem;
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    .dev-project-skills {
      li {
        font-size: 0.9em !important;
        padding: 2px 9px !important;
        line-height: 1.6em;
      }
    }
  }

  .logo {
    $size: 105px;

    width: $size;
    height: $size;
  }

  .header {
    margin-top: 0px;
  }

  .visual-projects-view {
    margin: 0 (-$wrapper-padding-x);
    padding: 0 $wrapper-padding-x;
    padding-bottom: 80px;
  }

  .facts-view {
    margin: 0 (-$wrapper-padding-x);
    padding: $wrapper-padding-y $wrapper-padding-x;
    background-color: transparent;
    padding-bottom: 80px;
  }

  .footer {
    padding-top: 20px;
    padding-bottom: $wrapper-padding-y;

    p {
      font-size: 1em !important;
    }
  }

  .fact {
    max-width: 100vw;

    li {
      font-size: 0.95rem;
      padding: 2px 9px;
      border: 2px solid transparent;
    }
  }
}

/* Mobile: 690px */
@media (max-width: 690px) {
  h1,
  p,
  li {
    margin-bottom: 25px;

    font-size: 2.55em !important;
    line-height: 1.36em;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .hero {
    margin-top: 50px;
    margin-bottom: 0px;

    h1 {
      font-size: 2.5em !important;
      line-height: 1.36em;
    }
  }

  .hexagon {
    .MuiSvgIcon-root {
      width: 75px;
      height: 75px;
    }
  }

  .hexagon2 {
    .MuiSvgIcon-root {
      width: 40px;
      height: 40px;
    }
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: block;
  }

  .left,
  .right {
    width: 100%;
  }
}

/* Mobile: 400px */
@media (max-width: 400px) {
  .description {
    transform: scale(0.9, 0.9);

    ul {
      margin: 0;
    }
  }
}

.non-selectable {
  @include notSelectable();
}

.selectable {
  @include selectable();
}

.full-width {
  width: 100%;
}

.hide-on-desktop {
  display: none;
}

// Debug
// * {
//   outline: 1px red solid !important;
//   visibility: visible !important;
// }
