@font-face {
  font-family: 'SF Compact Display';
  font-weight: 100;
  src: url('../fonts/SF-Compact-Display/SF-Compact-Display-Ultralight.woff2')
    format('woff2');
}

@font-face {
  font-family: 'SF Compact Display';
  font-weight: 200;
  src: url('../fonts/SF-Compact-Display/SF-Compact-Display-Thin.woff2')
    format('woff2');
}

@font-face {
  font-family: 'SF Compact Display';
  font-weight: 300;
  src: url('../fonts/SF-Compact-Display/SF-Compact-Display-Light.woff2')
    format('woff2');
}

@font-face {
  font-family: 'SF Compact Display';
  font-weight: 400;
  src: url('../fonts/SF-Compact-Display/SF-Compact-Display-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'SF Compact Display';
  font-weight: 500;
  src: url('../fonts/SF-Compact-Display/SF-Compact-Display-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'JetBrains Mono NL';
  src: url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.eot');
  src: url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.woff2') format('woff2'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.woff') format('woff'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.ttf')
      format('truetype'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Regular.svg#JetBrainsMonoNL-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono NL';
  src: url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.eot');
  src: url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.woff2') format('woff2'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.woff') format('woff'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.ttf') format('truetype'),
    url('../fonts/JetBrains-Mono/JetBrainsMonoNL-Bold.svg#JetBrainsMonoNL-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$fonts: (
  heading: 'SF Compact Display',
  body: 'SF Compact Display',
  mono: 'JetBrains Mono NL'
);

:export {
  @each $key, $value in $fonts {
    #{unquote($key)}: $value;
  }
}
