@import './colors';
@import './styles';

@mixin in() {
  transition: map-get($transitions, 'in');
}

@mixin out() {
  transition: map-get($transitions, 'out');
}

@mixin notDraggable() {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin selectable() {
  user-select: auto;
}

@mixin notSelectable() {
  user-select: none;
}

@mixin hexCursor() {
  cursor: var(--hex-cursor), crosshair !important;
}

@mixin backgroundBlur() {
  backdrop-filter: blur(map-get($radii, 'blur'));
}
